import request from './request'
import qs from 'qs'

export const LinkslaApi = (type, data, url, headers) => {
  if (type === 'GET' || type === 'get' || type === '') {
    if (headers) {
      return request({
        url: url,
        params: data,
        headers: headers
      })
    } else {
      return request({
        url: url,
        params: data
      })
    }
  } else {
    if (headers) {
      if (headers['Content-Type'] === 'application/json') {
        return request({
          url: url,
          method: type,
          data: JSON.stringify(data),
          headers: headers
        })
      } else {
        return request({
          url: url,
          method: type,
          data: qs.stringify(data),
          headers: headers
        })
      }
    } else {
      return request({
        url: url,
        method: type,
        data: qs.stringify(data)
      })
    }
  }
}
