import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3e0cf2d3"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "_dis"
};
import { onMounted, onUnmounted, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getMobileCaptcha, saveWebUserApi } from '@/api/login.js';
export default {
  __name: 'mobile',
  props: {
    type: {},
    visible: {}
  },
  emits: ['update:visible'],

  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const script = ref(null);
    const formRef = ref();
    const param = ref({
      userMobile: '',
      captchaValue: ''
    });
    const rules = ref({
      userMobile: [{
        required: true,
        message: '请输入手机号',
        trigger: 'change'
      }, {
        pattern: /^1[3456789]\d{9}$/,
        message: '请输入正确的手机号',
        trigger: 'blur'
      }],
      captchaValue: [{
        required: true,
        message: '请输入验证码',
        trigger: 'change'
      }]
    }); // 手机号登录 获取验证码

    const mobileCaptchaSpan = ref(null);
    const mobileCaptchaSpanFlag = ref(false); // 获取短信验证码

    const getMobileCaptchaFun = type => {
      if (mobileCaptchaSpanFlag.value) {
        return false;
      }

      formRef.value.validateField('userMobile', async valid => {
        if (valid) {
          const res = await getMobileCaptcha({
            smsSendType: type,
            userMobile: myEnc(param.value.userMobile) // eslint-disable-line no-undef

          });

          if (!res.flag) {
            ElMessage.error(res.message);
            return false;
          }

          let aaa = 60;
          const timer = setInterval(function () {
            aaa = aaa - 1;
            mobileCaptchaSpan.value.innerHTML = aaa + 's';
            mobileCaptchaSpan.value.classList.add('_dis');
            mobileCaptchaSpanFlag.value = true;

            if (aaa === 0) {
              mobileCaptchaSpanFlag.value = false;
              mobileCaptchaSpan.value.innerHTML = '获取验证码';
              mobileCaptchaSpan.value.classList.remove('_dis');
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    };

    const saveWebUser = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          const res = await saveWebUserApi({
            userMobile: myEnc(param.value.userMobile),
            // eslint-disable-line no-undef
            registerSource: props.type,
            captchaValue: param.value.captchaValue
          });

          if (res.flag) {
            emits('update:visible', false);
            param.value.userMobile = '';
            param.value.captchaValue = '';
            formRef.value.resetFields();
            ElMessageBox.alert('专属助理稍后与您取得联系，敬请留意接听。', '预约成功', {
              confirmButtonText: '确定',
              callback: action => {}
            });
          } else {
            ElMessage.error(res.message);
          }
        } else {}
      });
    }; // 添加 密码加密 js文件


    const addScriptFun = () => {
      script.value = document.createElement('script');
      script.value.src = '/static/myaes.js';
      document.head.appendChild(script.value);
    };

    onMounted(() => {
      addScriptFun();
    });
    onUnmounted(() => {
      script.value.remove();
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_form, {
        model: param.value,
        ref_key: "formRef",
        ref: formRef,
        rules: rules.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          prop: "userMobile"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: "手机号（必填）",
            modelValue: param.value.userMobile,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => param.value.userMobile = $event)
          }, {
            prefix: _withCtx(() => [_createVNode(_component_svg_icon, {
              icon: "phone"
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "captchaValue"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: "验证码（必填）",
            modelValue: param.value.captchaValue,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => param.value.captchaValue = $event)
          }, {
            prefix: _withCtx(() => [_createVNode(_component_svg_icon, {
              icon: "verification"
            })]),
            suffix: _withCtx(() => [!param.value.userMobile ? (_openBlock(), _createElementBlock("span", _hoisted_1, "获取验证码")) : (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = $event => getMobileCaptchaFun(5)),
              ref_key: "mobileCaptchaSpan",
              ref: mobileCaptchaSpan
            }, "获取验证码", 512))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"]), _createVNode(_component_el_button, {
        onClick: saveWebUser,
        disabled: !param.value.userMobile || !param.value.captchaValue
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(props.type === 6 ? '立即领取' : '确认提交'), 1)]),
        _: 1
      }, 8, ["disabled"])], 64);
    };
  }

};