import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-33c49d68"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "personalCenterWrap main"
};
const _hoisted_2 = {
  class: "leftWrap"
};
const _hoisted_3 = {
  class: "rightWrap"
};
import { ref, watch } from 'vue'; // import { Sort, SortUp, SortDown, EditPen } from '@element-plus/icons-vue'

import Article from './personalCenter/article.vue';
import Problem from './personalCenter/problem.vue';
import Tease from './personalCenter/tease.vue';
import FocusOn from './personalCenter/focusOn.vue';
import Collect from './personalCenter/collect.vue';
import Audit from './personalCenter/audit.vue';
import { useStore } from 'vuex';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import AuthorInfoLeft from './components/authorInfoLeft.vue';
export default {
  __name: 'personalCenter',

  setup(__props) {
    const store = useStore();
    const Route = useRoute();
    const userId = ref(Route.query.id); // 作者用户id

    const activeName = ref(Route.query.activeName || 'first');
    const userInfo = store.state.isLoginFlag // 当前登录用户信息
    ? store.state.userInfo : {};
    const selfFlag = ref(false);
    const pageRefreshFlag = ref(true);

    if (userId.value == userInfo.userId) {
      // 个人中心 / 他人中心
      selfFlag.value = true;
    }

    const isLoginFlagFun = () => {
      return store.state.isLoginFlag;
    };

    watch(isLoginFlagFun, () => {
      // 重新刷新页面
      const userInfo = store.state.isLoginFlag // 当前登录用户信息
      ? store.state.userInfo : {};

      if (userId.value == userInfo.userId) {
        // 个人中心 / 他人中心
        selfFlag.value = true;
      } else {
        selfFlag.value = false;

        if (activeName.value === 'sixth' || activeName.value === 'fifth') {
          activeName.value = 'first';
        }
      }
    }, {
      immediate: true,
      deep: true
    });
    onBeforeRouteUpdate(to => {// userId.value = Route.query.id
      // const userInfo = store.state.isLoginFlag // 当前登录用户信息
      //   ? store.state.userInfo
      //   : {}
      // if (userId.value == userInfo.userId) {
      //   // 个人中心 / 他人中心
      //   selfFlag.value = true
      // } else {
      //   selfFlag.value = false
      //   activeName.value = 'first'
      // }
    });
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");

      const _component_el_tabs = _resolveComponent("el-tabs");

      return pageRefreshFlag.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(AuthorInfoLeft, {
        userId: userId.value
      }, null, 8, ["userId"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => activeName.value = $event),
        class: "demo-tabs"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "文章",
          name: "first"
        }, {
          default: _withCtx(() => [activeName.value === 'first' ? (_openBlock(), _createBlock(Article, {
            key: 0,
            selfFlag: selfFlag.value,
            userId: userId.value
          }, null, 8, ["selfFlag", "userId"])) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "问答",
          name: "second"
        }, {
          default: _withCtx(() => [activeName.value === 'second' ? (_openBlock(), _createBlock(Problem, {
            key: 0,
            selfFlag: selfFlag.value,
            userId: userId.value
          }, null, 8, ["selfFlag", "userId"])) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "吐槽",
          name: "third"
        }, {
          default: _withCtx(() => [activeName.value === 'third' ? (_openBlock(), _createBlock(Tease, {
            key: 0,
            selfFlag: selfFlag.value,
            userId: userId.value
          }, null, 8, ["selfFlag", "userId"])) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "关注",
          name: "fourth"
        }, {
          default: _withCtx(() => [activeName.value === 'fourth' ? (_openBlock(), _createBlock(FocusOn, {
            key: 0,
            selfFlag: selfFlag.value,
            userId: userId.value
          }, null, 8, ["selfFlag", "userId"])) : _createCommentVNode("", true)]),
          _: 1
        }), selfFlag.value ? (_openBlock(), _createBlock(_component_el_tab_pane, {
          key: 0,
          label: "收藏",
          name: "fifth"
        }, {
          default: _withCtx(() => [activeName.value === 'fifth' ? (_openBlock(), _createBlock(Collect, {
            key: 0,
            selfFlag: selfFlag.value,
            userId: userId.value
          }, null, 8, ["selfFlag", "userId"])) : _createCommentVNode("", true)]),
          _: 1
        })) : _createCommentVNode("", true), selfFlag.value && _unref(userInfo).userId == 1 ? (_openBlock(), _createBlock(_component_el_tab_pane, {
          key: 1,
          label: "审核",
          name: "sixth"
        }, {
          default: _withCtx(() => [activeName.value === 'sixth' ? (_openBlock(), _createBlock(Audit, {
            key: 0,
            selfFlag: selfFlag.value,
            userId: userId.value
          }, null, 8, ["selfFlag", "userId"])) : _createCommentVNode("", true)]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue"])])])) : _createCommentVNode("", true);
    };
  }

};