import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store/index.js'

const service = axios.create({
  // baseURL: '/linksla',
  timeout: 90 * 1000
})

service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('expire') < Date.now()) {
      // 登录过期 退出登录
      store.commit('logoutFun')
    }
    config.headers.Authentication = localStorage.getItem('token')
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

service.interceptors.response.use(
  (response) => {
    const { data } = response
    if (data.code !== '1002') {
      return data
    } else {
      ElMessage.error('用户未登录')
      if (store.state.isLoginFlag) {
        store.commit('logoutFun')
      }
      return data
    }
  },
  (error) => {
    ElMessage.error(error.response.status + ' ' + error.response.statusText)
    if (error.response.data && error.response.data.code === '1002') {
      // 用户未登录
      if (store.state.isLoginFlag) {
        store.commit('logoutFun')
      }
    }
    return Promise.reject(new Error(error))
  }
)
export default service
