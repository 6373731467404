import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import Aside from '@/components/aside.vue';
import { onMounted, ref } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import { getRequest } from '@/utils/common.js';
import axios from 'axios';
export default {
  __name: 'App',

  setup(__props) {
    const store = useStore();
    const PageFooterFlag = ref(false);
    router.beforeEach(to => {
      // 网站title
      // document.title = 'LinkSLA官网'
      if (to.path.indexOf('forum') !== -1) {
        PageFooterFlag.value = true;
      } else {
        PageFooterFlag.value = false;
      }
    });

    const setIntervalFun = () => {
      if (localStorage.getItem('token')) {
        setInterval(() => {
          if (localStorage.getItem('expire') > Date.now()) {
            store.commit('logoutFun');
          }
        }, 1000);
      }
    };

    onMounted(() => {
      setIntervalFun();

      if (getRequest('source')) {
        const url = `//${window.location.host}/linksla/api/websiteTraffic/addWebsiteTraffic?source=${getRequest('source')}&visitPage=官网`;
        const service = axios.create({
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        service.get(url).then(res => {
          window.location.href = window.location.origin;
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_Title = _resolveComponent("Title");

      const _component_el_header = _resolveComponent("el-header");

      const _component_router_view = _resolveComponent("router-view");

      const _component_el_main = _resolveComponent("el-main");

      const _component_PageFooter = _resolveComponent("PageFooter");

      const _component_el_footer = _resolveComponent("el-footer");

      const _component_el_container = _resolveComponent("el-container");

      const _component_Login = _resolveComponent("Login");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_container, null, {
        default: _withCtx(() => [_createVNode(_component_el_header, {
          style: {
            "padding": "0"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_Title)]),
          _: 1
        }), _createVNode(_component_el_main, {
          style: {
            "line-height": "normal"
          }
        }, {
          default: _withCtx(() => [(_openBlock(), _createBlock(_component_router_view, {
            key: _ctx.$route.fullPath
          }))]),
          _: 1
        }), _withDirectives(_createVNode(_component_el_footer, {
          class: "pageFooter"
        }, {
          default: _withCtx(() => [_createVNode(_component_PageFooter)]),
          _: 1
        }, 512), [[_vShow, !PageFooterFlag.value]])]),
        _: 1
      }), _unref(store).state.isShowlogin ? (_openBlock(), _createBlock(_component_Login, {
        key: 0,
        class: "loginWrap"
      })) : _createCommentVNode("", true), _createVNode(Aside)], 64);
    };
  }

};