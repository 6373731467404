import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '@/views/home/index.vue'
import latestActivity from '@/views/latestActivity/index.vue'
import Case from '@/views/case/index.vue'
import Msp from '@/views/msp/index.vue'
import Product from '@/views/product/index.vue'
import Forum from '@/views/forum/index.vue'
import ArticleDetail from '@/views/forum/articleDetail.vue' // 文章详情
import ProblemDetail from '@/views/forum/problemDetail.vue' // 问答详情
import Teasing from '@/views/forum/teasing.vue' // 吐槽社区
import TeasingDetail from '@/views/forum/teasingDetail.vue' // 吐槽详情
import WriteArticle from '@/views/forum/writeArticle.vue' // 写文章
import WriteProblem from '@/views/forum/writeProblem.vue' // 写问题
import PersonalCenter from '@/views/forum/personalCenter.vue' // 个人中心
import HotTopic from '@/views/forum/hotTopic.vue' // 个人中心
import detail0 from '@/views/home/detail/detail0.vue'
import detail1 from '@/views/home/detail/detail1.vue'
import detail2 from '@/views/home/detail/detail2.vue'
import detail3 from '@/views/home/detail/detail3.vue'
import detail4 from '@/views/home/detail/detail4.vue'
import detail5 from '@/views/home/detail/detail5.vue'
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/detail0', name: 'detail0', component: detail0 },
    { path: '/detail1', name: 'detail1', component: detail1 },
    { path: '/detail2', name: 'detail2', component: detail2 },
    { path: '/detail3', name: 'detail3', component: detail3 },
    { path: '/detail4', name: 'detail4', component: detail4 },
    { path: '/detail5', name: 'detail5', component: detail5 },
    { path: '/latestActivity', component: latestActivity },
    { path: '/msp', component: Msp },
    { path: '/product', component: Product },
    { path: '/forum', name: '首页', component: Forum },
    { path: '/case', component: Case },
    {
      path: '/forum/articleDetail',
      name: '文章详情',
      component: ArticleDetail
    },
    {
      path: '/forum/problemDetail',
      name: '问答详情',
      component: ProblemDetail
    },
    { path: '/forum/teasing', name: '吐槽社区', component: Teasing },
    { path: '/forum/writeArticle', name: '写文章', component: WriteArticle },
    { path: '/forum/writeProblem', name: '写问答', component: WriteProblem },
    {
      path: '/forum/personalCenter',
      name: '个人中心',
      component: PersonalCenter
    },
    { path: '/forum/hotTopic', name: '热门话题', component: HotTopic },
    {
      path: '/forum/teasingDetail',
      name: '吐槽详情',
      component: TeasingDetail
    },
    {
      path: '/:cathchAll(.*)',
      name: '404',
      component: () => import('../views/404.vue')
    }
  ]
})

export default router
