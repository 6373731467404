// import { ref } from 'vue'
// import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
import { LinkslaApi } from '@/api/public.js'
import store from '@/store/index.js'
import router from '@/router/index.js'

// 页面路由跳转
const jumpFun = (path, query, index) => {
  let name
  if (
    path == router.currentRoute.value.path &&
    query.id == router.currentRoute.value.query.id
  ) {
    return
  }
  if (path === '/forum') {
    name = '首页'
  } else if (path === '/forum/articleDetail') {
    name = query.name || '文章详情'
  } else if (path === '/forum/problemDetail') {
    name = query.name || '问答详情'
  } else if (path === '/forum/teasing') {
    name = '吐槽社区'
  } else if (path === '/forum/writeArticle') {
    name = '写文章'
  } else if (path === '/forum/writeProblem') {
    name = '写问答'
  } else if (path === '/forum/personalCenter') {
    name = '个人中心'
  } else if (path === '/forum/hotTopic') {
    name = query.name ? '#' + query.name + '#' : '热门话题'
  } else if (path === '/forum/teasingDetail') {
    name = '吐槽详情'
  }
  if (index) {
    const aaa = store.state.routeList
    aaa.length = index
    store.commit('setRouteListFun', aaa)
  } else {
    if (
      (path == '/forum/writeArticle' || path == '/forum/writeProblem') &&
      !store.state.isLoginFlag
    ) {
      store.commit('isisShowloginFun')
      return false
    }
    if (store.state.routeList.length > 0) {
      const aaa = store.state.routeList
      let iii
      aaa.forEach((o, i) => {
        if (o.path == path && o.query.id == query.id) {
          iii = i + 1
        }
      })
      if (iii) {
        aaa.splice(iii - 1, 1)
      }
      aaa.push({
        path: path,
        query: query,
        name: name
      })
      if (aaa.length > 5) {
        aaa.splice(0, 1)
      }
      store.commit('setRouteListFun', aaa)
    } else {
      store.commit('setRouteListFun', [
        {
          path: router.currentRoute.value.path,
          query: router.currentRoute.value.query,
          name:
            router.currentRoute.value.path == '/forum/hotTopic'
              ? '#' + router.currentRoute.value.query.name + '#'
              : router.currentRoute.value.query.name ||
                router.currentRoute.value.name
        },
        { path: path, query: query, name: name }
      ])
    }
  }
  router.push({
    path: path,
    query: query
  })
}

// 点赞
const likeFun = async (id, type, obj) => {
  // id 文章问答id
  // type 类型
  // praiseExists 是否点赞
  if (!store.state.isLoginFlag) {
    store.commit('isisShowloginFun')
    return false
  }
  if (obj.loading) {
    return
  }
  obj.loading = true
  let url = ''
  if (obj.praiseExists) {
    url = '/linksla/bbs/pointPraise/deleteBbsPointPraise'
  } else {
    url = '/linksla/bbs/pointPraise/addAllBbsPointPraise'
  }
  const res = await LinkslaApi(
    'POST',
    {
      praiseIds: id,
      praiseType: type
      // userId: store.state.userInfo.userId
    },
    url,
    {
      'Content-Type': 'application/json'
    }
  )
  if (res.flag) {
    if (obj.praiseExists) {
      obj.praiseExists = 0
      obj.praiseNum--
      obj.loading = false
    } else {
      obj.praiseExists = 1
      obj.praiseNum++
      obj.loading = false
    }
  } else {
    ElMessage({
      type: 'error',
      message: res.message,
      showClose: true
    })
  }
}

// 关注
const focusOnFun = async (userId, obj) => {
  if (!store.state.isLoginFlag) {
    store.commit('isisShowloginFun')
    return false
  }
  let res
  if (obj.isFocusOn) {
    // 已关注 取消关注
    res = await LinkslaApi(
      'GET',
      {
        fUserId: userId,
        userId: store.state.userInfo.userId
      },
      '/linksla/bbs/bbsFocusOn/deleteBbsFocusOn'
    )
    if (res.flag) {
      obj.isFocusOn = 0
    }
  } else {
    // 未关注 添加关注
    res = await LinkslaApi(
      'POST',
      {
        fUserId: userId,
        userId: store.state.userInfo.userId
      },
      '/linksla/bbs/bbsFocusOn/addBbsFocusOn',
      {
        'Content-Type': 'application/json'
      }
    )
    if (res.flag) {
      obj.isFocusOn = 1
    }
  }
  if (res.flag) {
    ElMessage.success(res.message)
  } else {
    ElMessage({
      type: 'error',
      message: res.message,
      showClose: true
    })
  }
}

// 分享
const copyLinkFun = (url, id) => {
  if (id) {
    url = window.location.origin + '/#' + url + '?id=' + id
  } else {
    url = window.location.origin + '/#' + url
  }
  const oInput = document.createElement('input')
  oInput.value = url
  document.body.appendChild(oInput)
  oInput.select()
  document.execCommand('Copy')
  ElMessage({
    type: 'success',
    message: '复制成功！',
    showClose: true
  })
  oInput.remove()
}

// 删除
const deleteAllFun = (id, type, bf) => {
  // type 1 文章
  // type 2 问答
  // type 3 评论
  // type 4 回复

  ElMessageBox.confirm('您确定要删除吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      const res = await LinkslaApi(
        'GET',
        {
          id: id,
          type: type
        },
        '/linksla/bbs/article/deleteAll'
      )
      if (res.flag) {
        bf()
      } else {
        ElMessage({
          type: 'error',
          message: res.message || '删除失败！',
          showClose: true
        })
      }
    })
    .catch(() => {})
}

const formatMsgTime = (timespan) => {
  var dateTime = new Date(timespan) // 将传进来的字符串或者毫秒转为标准时间
  var year = dateTime.getFullYear()
  var month = dateTime.getMonth() + 1
  var day = dateTime.getDate()
  var hour = dateTime.getHours()
  var minute = dateTime.getMinutes()
  // var second = dateTime.getSeconds()
  var millisecond = dateTime.getTime() // 将当前编辑的时间转换为毫秒
  var now = new Date() // 获取本机当前的时间
  var nowNew = now.getTime() // 将本机的时间转换为毫秒
  var milliseconds = 0
  var timeSpanStr
  milliseconds = nowNew - millisecond
  if (milliseconds <= 1000 * 60 * 1) {
    // 小于一分钟展示为刚刚
    timeSpanStr = '刚刚'
  } else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) {
    // 大于一分钟小于一小时展示为分钟
    timeSpanStr = Math.round(milliseconds / (1000 * 60)) + '分钟前'
  } else if (
    1000 * 60 * 60 * 1 < milliseconds &&
    milliseconds <= 1000 * 60 * 60 * 24
  ) {
    // 大于一小时小于一天展示为小时
    timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前'
  } else if (
    1000 * 60 * 60 * 24 < milliseconds &&
    milliseconds <= 1000 * 60 * 60 * 24 * 15
  ) {
    // 大于一天小于十五天展示位天
    timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前'
  } else if (
    milliseconds > 1000 * 60 * 60 * 24 * 15 &&
    year === now.getFullYear()
  ) {
    timeSpanStr = month + '-' + day + ' ' + hour + ':' + minute
  } else {
    timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
  }
  return timeSpanStr
}
// 格式化日对象
const getNowDate = () => {
  var date = new Date()
  var sign2 = ':'
  var year = date.getFullYear() // 年
  var month = date.getMonth() + 1 // 月
  var day = date.getDate() // 日
  var hour = date.getHours() // 时
  var minutes = date.getMinutes() // 分
  var seconds = date.getSeconds() // 秒
  // 给一位数的数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (day >= 0 && day <= 9) {
    day = '0' + day
  }
  if (hour >= 0 && hour <= 9) {
    hour = '0' + hour
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds
  }
  return (
    year +
    '-' +
    month +
    '-' +
    day +
    ' ' +
    hour +
    sign2 +
    minutes +
    sign2 +
    seconds
  )
}

export {
  likeFun,
  jumpFun,
  copyLinkFun,
  deleteAllFun,
  focusOnFun,
  formatMsgTime,
  getNowDate
}
