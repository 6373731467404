import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "border": "1px solid #ccc"
  }
};
import { onBeforeUnmount, ref, shallowRef, watch } from 'vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import '@wangeditor/editor/dist/css/style.css'; // 引入 css

export default {
  __name: 'WEditor',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],

  setup(__props, {
    emit
  }) {
    const props = __props; // 编辑器实例，必须用 shallowRef

    const editorRef = shallowRef(); // 内容 HTML

    const valueHtml = ref('');
    watch(() => props.value, value => {
      valueHtml.value = value;
    }, {
      immediate: true,
      deep: true
    }); // 配置内容

    const mode = ref('simple'); // 'default' 或者 'simple'

    const toolbarConfig = {
      excludeKeys: ['insertVideo', 'insertTable', 'fullScreen', 'undo', 'redo', 'todo']
    };
    const editorConfig = {
      placeholder: '',
      MENU_CONF: {
        uploadImage: {
          base64LimitSize: 5 * 1024 * 1024
        }
      }
    };

    const handleCreated = editor => {
      editorRef.value = editor; // 记录 editor 实例，重要！
    }; // 组件销毁时，也及时销毁编辑器


    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });
    watch(valueHtml, n => {
      emit('update:modelValue', n);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Toolbar), {
        style: {
          "border-bottom": "1px solid #ccc"
        },
        editor: _unref(editorRef),
        defaultConfig: toolbarConfig,
        mode: mode.value
      }, null, 8, ["editor", "mode"]), _createVNode(_unref(Editor), {
        style: {
          "height": "400px",
          "overflow-y": "hidden"
        },
        class: _normalizeClass(_ctx.readonly ? 'wang_editor' : ''),
        modelValue: valueHtml.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => valueHtml.value = $event),
        defaultConfig: editorConfig,
        mode: mode.value,
        onOnCreated: handleCreated,
        ref: "wangeditorRef"
      }, null, 8, ["class", "modelValue", "mode"])]);
    };
  }

};