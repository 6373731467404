import request from './request'
import qs from 'qs'

// 微信登录二维码
export const getQrCodeUrl = (data) => {
  return request({
    url: '/linksla/api/getQrCodeUrl',
    method: 'GET',
    params: data
  })
}

// 图片验证码
export const getCaptcha = () => {
  return request({
    url: '/linksla/api/getCaptcha',
    method: 'GET',
    params: {}
  })
}

// 短信验证码
export const getMobileCaptcha = (data) => {
  return request({
    url: '/linksla/api/getMobileCaptcha',
    method: 'GET',
    params: data
  })
}

// 重置密码
export const updateUserPwdForCaptcha = (data) => {
  return request({
    url: '/linksla/api/updateUserPwdForCaptcha',
    method: 'POST',
    data: qs.stringify(data)
  })
}

export const loginFun = (data) => {
  return request({
    url: '/linksla/api/login',
    method: 'POST',
    data: qs.stringify(data)
  })
}

export const saveWebUserApi = (data) => {
  return request({
    url: '/linksla/api/sysUser/saveWebUser',
    method: 'POST',
    data: qs.stringify(data)
  })
}
