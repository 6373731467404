import { createStore } from 'vuex'

export default createStore({
  state: {
    isShowlogin: false, // 登录弹出框是否显示
    isLoginFlag: localStorage.getItem('userInfo') ? true : 0, // 是否登录
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {}, // 登录后的用户信息
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    routeList: []
  },
  mutations: {
    isisShowloginFun(state) {
      state.isShowlogin = !state.isShowlogin
    },
    isLoginFlagFun(state) {
      state.isLoginFlag = !state.isLoginFlag
    },
    userInfoFun(state, infoObj) {
      state.userInfo = infoObj
    },
    setRouteListFun(state, arr) {
      state.routeList = arr
    },
    logoutFun(state) {
      localStorage.clear()
      sessionStorage.clear()
      state.isLoginFlag = false
      state.token = ''
      state.userInfo = {}
    },
    setToken(state, token) {
      state.token = token
    }
  },
  actions: {},
  modules: {}
})
