import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/styles/index.scss'
import SvgIcon from '@/assets/icons'
import '@/styles/common.scss'

import Title from '@/components/title.vue' // 网站头部
import Login from '@/components/login.vue' // 登录页面
import PageFooter from '@/components/pageFooter.vue' // 网站底部

const app = createApp(App)
app.component({
  Title: Title,
  Login: Login,
  PageFooter: PageFooter
})
SvgIcon(app)
app.use(store).use(router)
app.use(ElementPlus)
app.mount('#app')
